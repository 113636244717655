<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('supervisor_approvals')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
                <HeaderMobile :title="$t('supervisor_approvals')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus = $event"
                              :isNewButton="true"
                              @new-button-click="showCreateModal">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear">

                <b-row>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.surname"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('Advisor')">
                            <staff-auto-suggest v-model="datatable.queryParams.filter.advisor_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                :setActive="true"
                                v-model="datatable.queryParams.filter.semester_id">
                            </semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"
                            ></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.department_code"
                            ></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :is_faculty_code_required="true"
                                :is_department_code_required="true"
                                v-model="datatable.queryParams.filter.program_code"
                            ></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="3">
                        <b-form-group :label="$t('semester')">
                            <parameter-selectbox
                                code="semester"
                                sort="code"
                                v-model="datatable.queryParams.filter.semester"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3" xl="3">
                        <b-form-group :label="$t('student_status')">
                            <parameter-selectbox
                                code="student_statuses"
                                v-model="datatable.queryParams.filter.student_status"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('students')">
                            <lined-textarea v-model="datatable.queryParams.filter.student_numbers"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{height:'10em' }"

                            >
                            </lined-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template v-slot:json-excel>
                    <json-excel :data="excelData"
                                :fields="excelFields"
                                type="xls"
                    >
                        <div class="mb-2 mr-2 mr-xl-0">
                            <b-button variant="success" block v-if="excelData.length > 0">
                                {{ $t('download') }}
                            </b-button>
                            <b-button variant="success" block v-if="excelData.length < 1" @click="exportExcel">
                                {{ $t('excel') }}
                            </b-button>
                        </div>
                    </json-excel>
                </template>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal size="md" ref="statusModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('period_status') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="statusForm">
                            <ValidationProvider name="period" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="''">
                                    <parameter-selectbox
                                        v-model="datatable.queryParams.filter.periodStatus"
                                        code="semester_statuses"
                                        :validateError="errors[0]">
                                    </parameter-selectbox>
                                </b-form-group>
                                <b-button @click="updateSemesterStatus" variant="primary">{{ $t('save') }}</b-button>
                            </ValidationProvider>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="md" ref="createModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('new') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="createForm">
                            <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="''">
                                    <semesters-selectbox
                                        v-model="datatable.queryParams.filter.semester_id"
                                        :validateError="errors[0]">
                                    </semesters-selectbox>
                                </b-form-group>
                                <b-button @click="createSemester" variant="primary">{{ $t('save') }}</b-button>
                            </ValidationProvider>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import StudentSemesterService from "@/services/StudentSemesterService";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import StaffAutoSuggest from "@/components/interactive-fields/StaffAutoSuggest.vue";
import SupervisorApprovals from "@/services/SupervisorApprovals";
import JsonExcel from "vue-json-excel";

export default {
    components: {
        StaffAutoSuggest,
        LinedTextarea,
        ValidationObserver,
        ValidationProvider,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        AcademicYearsSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        ParameterSelectbox,

        CommonModal,
        SemestersSelectbox,
        JsonExcel,
    },
    metaInfo() {
        return {
            title: this.$t("supervisor_approvals"),
        };
    },
    data() {
        return {
            excelData: [],
            periodStatus: null,
            semesterId: null,
            semester_id: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '#',
                        field: "id",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: "student_number",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: "name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: "surname",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("school"),
                        field: "school",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("program"),
                        field: "program",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("advisor"),
                        field: "advisor",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: "academic_year",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("semester"),
                        field: "semester",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("level_of_study"),
                        field: "level_of_study",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("status"),
                        field: "status",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("period_number"),
                        field: "period_number",
                        hidden: false,
                        sortable: false,
                    },
                ],

                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-id",
                    page: 1,
                    limit: 20,
                    excel: true,
                },
            },

        };
    },
    created() {
        this.filterSet();
    },
    computed: {
        excelFields() {
            const columns = [...this.datatable.columns]
            columns.shift()
            const fields = {}
            columns.forEach((itm, index) => {
                if (itm.label === '#') {
                    columns.splice(index, 1);
                }
                fields[itm.label.replace("<BR>", " ")] = itm.field
            })
            return fields
        }
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {};
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            return SupervisorApprovals.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        exportExcel() {
            this.getRows().then(() => {
                this.excelData = this.datatable.rows
            })
        },
        showPeriodStatusModal(row) {
            this.periodStatus = row.semester_status
            this.semesterId = row.id
            this.$refs.statusModal.$refs.commonModal.show()
        },
        async updateSemesterStatus() {
            const isValid = await this.$refs.statusForm.validate();
            if (isValid) {
                let formData = {
                    semester_status: this.periodStatus
                }
                StudentSemesterService.updateStudentSemester(formData, this.semesterId).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$refs.statusModal.$refs.commonModal.hide()
                    this.semesterId = null
                    this.periodStatus = null
                }).catch(e => {
                    this.showErrors(e, this.$refs.statusForm)
                })
            }
        },
        deletePeriodStatus(row) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then((result) => {
                if (result.isConfirmed) {
                    StudentSemesterService.del(row.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.getRows()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                }
            })
        },

        showCreateModal() {
            this.$refs.createModal.$refs.commonModal.show()
        },

        async createSemester() {
            const isValid = await this.$refs.createForm.validate();
            if (isValid) {
                StudentSemesterService.create(this.semester_id).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$refs.createModal.$refs.commonModal.hide()
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch(e => {
                    this.showErrors(e, this.$refs.createForm)
                })
            }
        }
    }
}
</script>

